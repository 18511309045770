import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { ClientOnly, ExternalEmbed } from '../components/lib';

const SponsorPage = () => (
  <Layout>
    <SEO
      title='Donate'
      keywords={[
        `fathering.me`,
        `fatherlessness`,
        `new father`,
        `unplanned`,
        `sponsor`,
      ]}
    />
    <ClientOnly>
      <ExternalEmbed
        title='sponsor a mentorship'
        src={process.env.GATSBY_SPONSOR_URL}
        scrolling='no'
        frameborder='0'
        border='0'
      />
    </ClientOnly>
  </Layout>
);

export default SponsorPage;
